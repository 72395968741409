<template>
    <div>
        <header-portrate />
    <div class="m-2 pa-2">
        <bread-crumbs :items="items" />
        <tabs-comp :tabs="tabs" />

        <v-row class="mainrow m-2" :style="`direction:`+lang.dir">
        <v-row>
            <TableListCard :tableList="$store.state.customers" />
        </v-row>
        <v-row class="mt-10">
            <v-col
            cols="12"
            md="8"
            sm="12">
            <b-input-group class="mt-3">
                <b-form-input placeholder="Start Date" type="date" v-model="sdate"></b-form-input>
                <b-form-input placeholder="End Date"  type="date" v-model="edate"></b-form-input>
                <b-form-input :placeholder="lang.mobile"  v-model="mobile"></b-form-input>
                <b-input-group-append style="border:1px solid gold">
                <b-button variant="light" style="background:gold"  @click="getCustomers()"><i class="fas fa-search"></i></b-button>
                </b-input-group-append>
            </b-input-group>
            </v-col>
            <v-col cols="12" md="4" sm="12" class="text-end">
            <b-button
                v-b-toggle.add_crmcase
                class="btn-sm btn "
                style="margin-top:11px;width:150px;background:transparent;border:none"
                id="multiply-button" 
                >
                <div style="width:150px;" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_project}} &nbsp;	&nbsp;	<i class="fas fa-caret-square-left"></i></div>
                
                </b-button>
            </v-col>
            <v-col
            cols="12"
            md="3"
            sm="12"
            style="display:none;"
            >
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="lang.search"
                    single-line
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row class="d-none d-md-flex" style="display:none !important;">
            <v-col
            cols="12"
            md="6"
            sm="12">
            <b-input-group class="mt-3">
                <b-form-input placeholder="Start Date" type="date" v-model="sdate"></b-form-input>
                <b-form-input placeholder="End Date"  type="date" v-model="edate"></b-form-input>
                <b-form-input placeholder="Customer Mobile"  v-model="mobile"></b-form-input>
                <b-input-group-append style="border-left:1px solid #CCC">
                <b-button variant="light" style="background: darkblue !important;font-size:14px;color:#FFF" @click="getCustomers()">بحث</b-button>
                
                <!-- <b-button variant="light" style="background: orange !important;font-size:14px;color:#FFF" @click="gotoDaily()">يومية</b-button> -->
                </b-input-group-append>
            </b-input-group>
            </v-col>
            <v-col
            cols="12"
            md="3"
            sm="12"
            >
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="بحث"
                    single-line
                    hide-details
                ></v-text-field>
            </v-col>
            
            <v-col
            cols="12"
            md="3"
            sm="12"
            style="text-align:right;"
            >
                <v-btn
                v-b-toggle.add_crmcase
                class="btn-sm"
                style="width:100px !important;background: green !important;color:#FFF !important;margin-top:23px;"
                >إضافة عميل</v-btn>
            </v-col>
        </v-row>
        <v-row class="d-none d-md-flex">
            <v-col cols="12" style="direction: rtl;">
            <v-data-table
                :headers="headers"
                :items="tablerows"
                :search="search"
                :items-per-page="15"
                :page.sync="page"
                width="100%"
                id="MainTable"
                hide-default-footer
                @page-count="pageCount = $event"
                fixed-header
                class="table-sm"
            >
                <template v-slot:item="row">
                <tr>
                    <td style="border-bottom:1px solid #fff !important;text-align:center;direction:rtl;background:#000">
                        <b-button class="btn btn-sm" style="background:#000;color:#fff;border:none" @click="edit(row.item,3)" v-b-toggle.viewCRMCase>تعديل &nbsp;	&nbsp;	<i :class="`fas fa-caret-`+lang.lalgin"></i></b-button>
                    </td>
                    <td style="text-align:center;">{{ row.item.mobile }}</td>
                    <td style="text-align:center;" nowrap>{{ row.item.full_name }}</td>
                    <td style="text-align:center;direction:ltr" nowrap>{{ row.item.company }}</td>
                    <td style="text-align:center;direction:ltr" nowrap>{{ row.item.vatid }}</td>
                    <td style="text-align:center;direction:ltr" nowrap>{{ row.item._activity }}</td>
                    <td style="text-align:center;" nowrap>{{ row.item.posnumebr }}</td>
                    <td style="text-align:center;">{{ row.item._systemtype }}</td>
                    <td style="text-align:center;">{{ row.item._need_computers }}</td>
                    <td style="text-align:center;">{{ row.item.full_price }}</td>
                    <td :style="`text-align:center;color:#FFF;background:`+row.item.bgcolor">{{ row.item._status }}</td>
                    <td style="text-align:center;">{{ row.item._agentid }}</td>
                </tr>
                </template>
            </v-data-table>
            
            </v-col>
            <v-col cols="12" style="margin-bottom:25px;margin-top:-15px;">

            <v-container>
                <div class="text-center pt-2">
                <v-pagination
                    v-model="page"
                    value="10"
                    :length="pageCount"
                ></v-pagination>
                </div>
            </v-container>
            </v-col>
        </v-row>
        </v-row>
        <addCRMcase />
        <viewCRMCase ref="vcrmcase" />
        <addInvoice :custom="custom" :cardid="$route.params.id" />
    </div>
    </div>
</template>

<script>
import axios from 'axios' 
import addCRMcase from '@/components/addCRMcase.vue'
import addInvoice from '@/components/addInvoice.vue'
import TableListCard from '@/components/TableListCard.vue'
import BreadCrumbs from '@/components/breadCrumbs.vue'
import HeaderPortrate from '@/components/Header-Portrate.vue'
import TabsComp from '@/components/tabsComp.vue'
import viewCRMCase from '@/components/viewCRMCase.vue'
export default{
    components:{addCRMcase,addInvoice,TableListCard, 
            BreadCrumbs, HeaderPortrate,TabsComp,viewCRMCase},
    data() {
        return {
            custom: null,
            editid:null,
            select: { text: 'الاجراء', value: '0' },
            oitems: [
                { text: 'الاجراء', value: '0' },
                { text: 'تغيير الحالة', value: '1' },
                { text: 'انشاء فاتورة', value: '2' },
            ],
           sdate:'',
           edate: '',
           mobile: '',
           search: '',
           pageCount: 20,
           full_price:0,
           page:1,
           
            headers: [
                {
                    text: 'الاجراءات',
                    align: '',
                    filterable: false,
                    sortable:false,
                    value: ''
                },
                {
                    text: 'الجوال',
                    align: 'center',
                    filterable: true,
                    value: 'mobile',
                    sortable:false,
                },
                {
                    text: 'العميل',
                    align: 'center',
                    filterable: true,
                    sortable:false,
                    value: 'full_name',
                },
                {
                    text: 'المنشأة',
                    align: 'center',
                    sortable:false,
                    filterable: true,
                    value: 'company',
                },
                {
                    text: 'VAT ID',
                    align: 'center',
                    filterable: true,
                    sortable:false,
                    value: 'vatid',
                },
                {
                    text: 'النشاط',
                    align: 'center',
                    filterable: true,
                    sortable:false,
                    value: '_activity',
                },
                {
                    text: 'النقاط',
                    align: 'center',
                    filterable: true,
                    sortable:false,
                    value: '_posnumebr',
                },
                {
                    text: 'الترخيص',
                    align: 'center',
                    filterable: true,
                    sortable:false,
                    value: '_systemtype',
                },
                {
                    text: 'الأجهزة',
                    align: 'center',
                    filterable: true,
                    sortable:false,
                    value: '_need_computers',
                },
                {
                    text: 'الاجمالي',
                    align: 'center',
                    filterable: true,
                    sortable:false,
                    value: 'full_price',
                },
                {
                    text: 'الحالة',
                    align: 'center',
                    filterable: true,
                    sortable:false,
                    value: '_status',
                },
                {
                    text: 'المندوب',
                    align: 'center',
                    filterable: true,
                    sortable:false,
                    value: '_agentid',
                },
                
            ],
            tablerows: [],
            statusColor: [
                '','#eee','#f6db29','#fbab3d','#784bd1','#00c877','#e2445e','blue','green'
            ]
        }
    },
    beforeCreate() {
        this.$store.state.connected = true;
    },
    created() {
        this.getCustomers();
        setInterval(() => {this.updateForm();},1000)
        this.getProducts();
        // this.$store.state.headerIcone = 4;
        // this.$store.state.licenseType.cars = true;
        // console.log(this.$router.options.routes[1])
    },
    methods: {
        edit(id,t){
            if(t == 1)
                this.editid = id;
            else if (t==2){
                this.custom = id;
            }
            else if (t==3){
                this.$refs.vcrmcase.orderid = id.id;
                this.$refs.vcrmcase.crminfo = id;
                this.$refs.vcrmcase.getOrder();
                this.$refs.vcrmcase.updateChiled();
                //this.$router.push({name:'view-order', params: { id: id.id }})
            }
        },
        updateForm(){
            this.tablerows = this.$store.state.customers;           
        },
        getCustomers() {
            // // console.log("asdfa",this.$cookies.get(this.$COOKIEPhase));
            const post = new FormData();
            post.append('type','getCRM');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki)); 
            post.append("data[sdate]",this.sdate)
            post.append("data[edate]",this.edate)
            post.append("data[mobile]",this.mobile)
            axios.post(this.$SAMCOTEC.r_path,post)
            .then((response) => {
                const res = response.data;
                // console.log(res);
                const ll = [];
                if(res.results.data.length > 0){
                    let alcus = res.results.data;
                    for(let i=0;i<alcus.length;i++){
                        //alcus[i].bgcolor = this.statusColor[alcus[i].status];
                        ll.push(alcus[i]);
                    }
                    
                }
                this.$store.state.customers = ll;
            });
        },
        getProducts(){
            const post = new FormData();
            post.append('type','getProducts');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[ids]','0');
            axios.post(this.$SAMCOTEC.r_path,post)
            .then((response) => {
                const res = response.data;
                // if(res.results.data.length > 0){
                //     this.$store.state.products = res.results.data;                
                // }
            });
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function(){
            return {
                text: 'قائمة العملاء',
                disabled: true,
                href: '/',
            }
        },
        tabs: function(){
            return [
                {
                    index:6,name:this.lang.projects,href:'/customers',class:'mytab',status:1
                },
                {
                    index:0,name:this.lang.customers,href:'/clientList',class:'mytab3',status:1
                },
                {
                    index:5,name:this.lang.invoices,href:'/invoicelist',class:'mytab3',status:1
                },
                {
                    index:1,name:this.lang.new_quotations,href:'/quotations/n/1',class:'mytab3',status:1
                },
                {
                    index:2,name:this.lang.invoiced_quots,href:'/quotations/i/2',class:'mytab3',status:2
                },
                {
                    index:3,name:this.lang.canceled_quots,href:'/quotations/c/3',class:'mytab3',status:3
                },
            ]
        }
    },
}
</script>

<style>

.dropdown-toggle::after{
    display: none !important;
}
</style>